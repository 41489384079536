import Layout from 'components/Layout/Layout'
import { LayoutTextSection } from 'components/Layout/LayoutTextSection'
import Link from 'components/Link/Link'
import SEO from 'components/SEO/SEO'

const HowToOrderPage: React.FC = () => (
  <Layout>
    <SEO title="Zasady wypożyczeń" />

    <LayoutTextSection mt="xl">
      <h1>Jak możesz skorzystać z naszej wypożyczalni</h1>
      <ol>
        <li>
          Zapoznaj się z naszym{' '}
          <Link to="/dekoracje">katalogiem dekoracji</Link> i{' '}
          <strong>wybierz interesujące Cię przedmioty.</strong>
        </li>
        <li>
          <Link to="/kontakt">Napisz do nas</Link> i{' '}
          <strong>zapytaj o dostępność</strong> przedmiotów w wybranym terminie.
          Pamiętaj o podaniu nazw lub kodów interesujących Cię przedmiotów oraz
          ich ilości.
        </li>
        <li>
          Określ w jaki sposób chcesz je otrzymać. Oferujemy{' '}
          <strong>
            przesyłkę kurierem, odbiór osobisty lub dostawę naszym transportem
          </strong>{' '}
          - opcja możliwa tylko na terenie Warszawy i okolic dla zamówień
          powyżej 250zł. Uwaga nie wszystkie elementy możemy wysyłać kurierem, w
          szczególności dotyczy to produktów szklanych i gabarytowych. Koszty
          naszego transportu ustalane są indywidualnie.
        </li>
        <li>
          Po potwierdzeniu przez nas dostępności{' '}
          <strong>podpisujesz z nami umowę.</strong>
        </li>
        <li>
          <strong>Wpłacasz zadatek</strong> potwierdzając w ten sposób
          rezerwacje dekoracji w wybranym terminie.
        </li>
        <li>
          Rozliczasz zamówienie wraz z 50% kaucją najpoźniej na{' '}
          <strong>7 dni przed imprezą.</strong>
        </li>
        <li>
          <strong>Odbierasz przedmioty</strong> w wybranym terminie w określony
          wcześniej sposób.
        </li>
        <li>
          Dekorujesz swoje przyjęcie i <strong>cieszysz się efektem</strong>{' '}
          własnoręcznie przygotowanych dekoracji.
        </li>
        <li>
          Pakujesz i zwracasz wypożyczone dekoracje w ustalonym terminie, tak
          abyśmy mogli przygotować je na kolejne przyjęcie. Jeśli wszystkie
          przedmioty wrócą bez opóźnień i w niezmienionym stanie{' '}
          <strong>otrzymujesz zwrot kaucji.</strong>
        </li>
      </ol>
      <p>
        Szczegółowy opis zasad dotyczących wypożyczeń dostępny jest w naszym
        {` `}
        <Link to="/regulamin">Regulaminie.</Link>
      </p>
      <p>
        Powrót na{` `}
        <Link to="/">stronę główną.</Link>
      </p>
    </LayoutTextSection>
  </Layout>
)

export default HowToOrderPage
